import React from "react";
import { Helmet } from "react-helmet";
import Spacing from "../Spacing";
import SectionHeadingStyle3 from "../SectionHeading/SectionHeadingStyle3";
import { pageTitle } from "../../helpers/PageTitle";
import image from "./images/portfolio_tgg.jpg";

const title = "Tenerife GG";
const description = "";

export default function PortfolioTgg() {
  pageTitle(title);
  return (
    <>
      <Helmet>
        {/*  OG metadata */}

        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
        {/* og current url */}
        <meta property="og:url" content={window.location.href} />

        {/*  END OG metadata */}
      </Helmet>
      {/* <Spacing lg="70" md="70" /> */}
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3 title={title} subTitle="Detalles del proyecto" />
      <Spacing lg="75" md="60" />
      <div className="container">
        <div className="cs_portfolio_details">
          <img src={image} alt="Thumb" />
          <Spacing lg="100" md="40" />
          <div className="cs_portfolio_details_in">
            <div className="cs_portfolio_details_right">
              <h2>Resumen del proyecto:</h2>
              <p style={{ fontWeight: 100 }}>
                <span>
                  <span>
                    Evento multitudinario de proyección internacional celebrado
                    en Santa Cruz de Tenerife, en el Recinto Ferial, de carácter
                    multidisciplinar y comprometido con las nuevas tecnologías,
                    las tendencias y el desarrollo de la sociedad canaria.
                    Reuniendo a jugadores, desarrolladores, creadores de
                    contenido, fans de la cultura alternativa, educadores,
                    estudiantes y, además, empresas y emprendedores del sector
                    tecnológico, este festival ofrece una experiencia única e
                    integradora en más de 50.000m2.
                  </span>
                </span>
              </p>
              <p style={{ fontWeight: 100 }}>&nbsp;</p>
              <p style={{ fontWeight: 100 }}>
                <span>
                  <span>Principales áreas del evento:</span>
                </span>
              </p>
              <ul>
                <li style={{ fontWeight: 100 }}>
                  <span>
                    <span>
                      <strong>Zona Lan Party</strong>
                    </span>
                    <span>
                      {" "}
                      para más de 1.500 participantes, con acceso ininterrumpido
                      durante 6 días, 24 horas. Cuenta con diferentes shows y
                      actividades que dinamizan la participación de los
                      usuarios.
                    </span>
                  </span>
                </li>
                <li style={{ fontWeight: 100 }}>
                  <span>
                    <span>
                      <strong>Competiciones esports</strong>
                    </span>
                    <span>
                      {" "}
                      de los videojuegos más demandados por la comunidad.
                    </span>
                  </span>
                </li>
                <li style={{ fontWeight: 100 }}>
                  <span>
                    <span>
                      Cultura alternativa, dedicándole grandes esfuerzos al
                      K-pop y al Cosplay.
                    </span>
                  </span>
                </li>
                <li style={{ fontWeight: 100 }}>
                  <span>
                    <span>
                      <strong>Hobby GG</strong>
                    </span>
                    <span>
                      , el espacio dedicado a todas las aficiones relacionadas
                      con la cultura gamer, incluyendo juegos de mesa,
                      exposiciones y talleres.
                    </span>
                  </span>
                </li>
                <li style={{ fontWeight: 100 }}>
                  <span>
                    <span>
                      <strong>Zona profesional</strong>
                    </span>
                    <span>
                      {" "}
                      con conferencias, talleres, master-classes y
                      demostraciones.
                    </span>
                  </span>
                </li>
                <li style={{ fontWeight: 100 }}>
                  <span>
                    <span>
                      <strong>EBLive Awards</strong>
                    </span>
                    <span>, la gala de premios de la industria esports.</span>
                  </span>
                </li>
              </ul>
              <p style={{ fontWeight: 100 }}>&nbsp;</p>
              <p style={{ fontWeight: 100 }}>
                <span>
                  <span>Objetivos:</span>
                </span>
              </p>
              <ul style={{ fontWeight: 100 }}>
                <li>
                  <span>
                    <span>
                      <strong>Fomentar la comunidad.</strong>
                    </span>
                    <span>
                      {" "}
                      Crear un espacio donde los aficionados y profesionales
                      puedan conectarse, compartir experiencias y fortalecer la
                      comunidad de videojuegos y esports en las Islas Canarias.
                    </span>
                  </span>
                </li>
                <li>
                  <span>
                    <span>
                      <strong>Impulsar la innovación.</strong>
                    </span>
                    <span>
                      {" "}
                      Promueve el talento local y ofrece una plataforma para que
                      los desarrolladores y creativos muestren sus proyectos al
                      público.
                    </span>
                  </span>
                </li>
                <li>
                  <span>
                    <span>
                      <strong>Educar y capacitar.</strong>
                    </span>
                    <span>
                      {" "}
                      Proporciona oportunidades de aprendizaje a través de
                      charlas, talleres y paneles, ayudando a los asistentes a
                      mantenerse al día con las últimas tendencias y habilidades
                      necesarias en la industria.
                    </span>
                  </span>
                </li>
              </ul>
              <p>&nbsp;</p>
              <p style={{ fontWeight: 100 }}>
                <span>
                  <span>
                    La edición de 2023 recibió alrededor de 50.000 visitantes,
                    generando un impacto económico en Tenerife de 3,5 millones
                    de euros y alcanzando una audiencia publicitaria potencial
                    de 133.391.181 personas.
                  </span>
                </span>
              </p>
            </div>
          </div>
          {/* <Spacing lg="115" md="60" /> */}
          {/* <div className="cs_portfolio_details_gallery">
            <div>
              <img src="/images/others/portfolio_details_2.jpeg" alt="Thumb" />
            </div>
            <div>
              <img src="/images/others/portfolio_details_3.jpeg" alt="Thumb" />
            </div>
            <div>
              <img src="/images/others/portfolio_details_4.jpeg" alt="Thumb" />
            </div>
          </div> */}
        </div>
        {/* <Spacing lg="90" md="60" /> */}
        {/* <div className="cs_page_navigation cs_center">
          <div>
            <a href="/" className="cs_text_btn cs_type1">
              <Icon icon="cil:arrow-left" className="cs_fs_21" />
              <span>Prev Project</span>
            </a>
          </div>
          <div>
            <a href="/" className="cs_text_btn">
              <span>Next Project</span>
              <Icon icon="cil:arrow-right" className="cs_fs_21" />
            </a>
          </div>
        </div> */}
      </div>
      <Spacing lg="80" md="40" />
    </>
  );
}
