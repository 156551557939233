import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function Service({ data }) {
  const [active, setActive] = useState(0);
  const handelActive = (index) => {
    setActive(index);
  };
  return (
    <div className="cs_iconbox_3_list">
      {data?.map((item, index) => (
        <div
          className={`cs_hover_tab ${active === index ? "active" : ""}`}
          key={index}
          onMouseEnter={() => handelActive(index)}
        >
          <Link to={item.href} className="cs_iconbox cs_style_3_flex_end">
            <>
              <div className="cs_image_layer cs_style1 cs_size_md">
                <div className="cs_image_layer_in">
                  <img
                    src="/images/servicios.jpg"
                    alt="Thumb"
                    className="cs_radius_15 cs_img_md"
                  />
                </div>
              </div>
              {/* Ocultamos los botones por ahora */}
              <span
                className="cs_iconbox_icon cs_center"
                style={{ display: "none" }}
              ></span>
              <div className="cs_iconbox_in">
                <h2
                  className="cs_iconbox_title cs_fs_29"
                  style={{
                    textAlign: "left",
                    paddingLeft: "20px",
                  }}
                >
                  {item.title}
                </h2>
                <div
                  className="cs_iconbox_subtitle"
                  style={{ fontWeight: 100 }}
                >
                  {item.subtitle}
                </div>
              </div>
            </>
            <div style={{ marginRight: "20px" }}>{item.icon}</div>
          </Link>
        </div>
      ))}
    </div>
  );
}
