import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DropDown from "./DropDown";

export default function Header({
  logoUrl,
  colorVariant,
  cart,
  actionBtnText,
  actionBtnUrl,
}) {
  const [isSticky, setIsSticky] = useState(false);
  const [mobileToggle, setMobileToggle] = useState(false);
  const currentItem = window.location.pathname;

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      className={`cs_site_header cs_style_1 cs_sticky_header ${
        colorVariant ? colorVariant : "cs_primary_color"
      } ${isSticky ? "cs_gescout_show" : ""}`}
    >
      <div className="cs_main_header">
        <div className="container">
          <div className="cs_main_header_in">
            <div className="cs_main_header_left">
              <Link className="cs_site_branding" to="/">
                <img src={logoUrl} alt="Logo" />
              </Link>
            </div>
            <div className="cs_main_header_center">
              <div className="cs_nav cs_medium cs_primary_font">
                <ul
                  className={`${
                    mobileToggle ? "cs_nav_list cs_active" : "cs_nav_list"
                  }`}
                >
                  <li>
                    <Link
                      to="/"
                      className={currentItem === "/" ? "cs_selected_item" : ""}
                    >
                      HOME
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="about"
                      className={
                        currentItem === "/about" ? "cs_selected_item" : ""
                      }
                    >
                      SOBRE NOSOTROS
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="portfolio"
                      className={
                        currentItem === "/portfolio" ? "cs_selected_item" : ""
                      }
                    >
                      PORTFOLIO
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="services"
                      className={
                        currentItem === "/services" ? "cs_selected_item" : ""
                      }
                    >
                      SERVICIOS
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="contact"
                      className={
                        currentItem === "/contact" ? "cs_selected_item" : ""
                      }
                    >
                      CONTACTO
                    </Link>
                  </li>
                </ul>
                <span
                  className={
                    mobileToggle
                      ? "cs_menu_toggle cs_teggle_active"
                      : "cs_menu_toggle"
                  }
                  onClick={() => setMobileToggle(!mobileToggle)}
                >
                  <span></span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
