import React from 'react';
import parse from 'html-react-parser';
import Button from '../Button';
import Spacing from '../Spacing';

export default function CtaStyle2({ title, btnText, btnUrl }) {
  return (
    <div className="cs_cta cs_style_3 text-center">
      <h2 className="cs_cta_title cs_fs_50 mb-0">
        <span>
          {parse(title)}
          <svg
            width={84}
            height={77}
            viewBox="0 0 84 77"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.5">
              <path
                d="M64.8361 15.0076C60.9794 17.1059 57.7008 20.1708 53.9802 22.4974C50.3325 24.7754 46.9324 27.5246 43.6247 30.2349C36.9169 35.7187 30.7919 42.1594 26.6584 49.8046C26.1096 50.8198 27.4502 51.9223 28.2856 51.0578C34.192 44.9037 40.2586 38.9147 46.4273 33.0375C49.429 30.1685 52.4729 27.3368 55.5588 24.5423C58.8228 21.594 62.5677 19.1994 65.7881 16.2413C66.4584 15.6196 65.5986 14.5899 64.8361 15.0076Z"
                fill="#A3A3A3"
              />
              <path
                d="M82.3815 43.7793C77.9911 43.7567 73.6406 44.8596 69.2592 45.1026C64.965 45.3382 60.6605 46.106 56.4556 46.8843C47.9344 48.4516 39.4622 51.1384 32.1532 55.8409C31.1827 56.4653 31.8251 58.0779 32.974 57.7236C41.1165 55.1825 49.3197 52.8633 57.5584 50.6913C61.5718 49.6264 65.6042 48.6144 69.6554 47.6554C73.9369 46.6475 78.3724 46.3567 82.6202 45.3192C83.5073 45.098 83.251 43.7813 82.3815 43.7793Z"
                fill="#A3A3A3"
              />
              <path
                d="M32.2965 0.725762C30.183 4.57405 29.0778 8.92393 27.202 12.891C25.3612 16.7778 23.9834 20.9274 22.6622 24.9946C19.9763 33.2318 18.2976 41.9599 18.9456 50.6268C19.0316 51.7776 20.7553 51.982 20.9918 50.8032C22.6412 42.4344 24.5147 34.1181 26.5343 25.8407C27.5122 21.8052 28.5457 17.7783 29.6347 13.76C30.7905 9.51616 32.6501 5.47878 33.7639 1.25032C33.9925 0.365116 32.7129 -0.0375403 32.2965 0.725762Z"
                fill="#A3A3A3"
              />
            </g>
          </svg>
        </span>
      </h2>
      <Spacing lg="44" md="30" />
      <Button btnText={btnText} btnUrl={btnUrl} />
    </div>
  );
}
