import React from "react";
import { Helmet } from "react-helmet";
import Spacing from "../Spacing";
import SectionHeadingStyle3 from "../SectionHeading/SectionHeadingStyle3";
import { pageTitle } from "../../helpers/PageTitle";
import image from "./images/jeme-detalles.jpg";

const title = "Juegos Escolares Minecraft Education";
const description =
  "Los Juegos Escolares Minecraft Education fueron un programa piloto en el que 30 centros públicos y concertados de Canarias tuvieron la oportunidad de aprender el currículo de sexto de primaria dentro de Minecraft.";

export default function PortfolioJeme() {
  pageTitle("Juegos Escolares Minecraft Education");
  return (
    <>
      <Helmet>
        {/*  OG metadata */}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
        {/* og current url */}
        <meta property="og:url" content={window.location.href} />

        {/*  END OG metadata */}
      </Helmet>
      {/* <Spacing lg="70" md="70" /> */}
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title="Juegos Escolares Minecraft Education"
        subTitle="Detalles del proyecto"
      />
      <Spacing lg="75" md="60" />
      <div className="container">
        <div className="cs_portfolio_details">
          <img src={image} alt="Thumb" />
          <Spacing lg="100" md="40" />
          <div className="cs_portfolio_details_in">
            <div className="cs_portfolio_details_right">
              <h2>Resumen del proyecto:</h2>
              <p style={{ fontWeight: 100 }}>
                Programa piloto en el que treinta centros educativos de las
                Islas Canarias, tanto públicos como concertados, tuvieron la
                oportunidad de aprender el currículo de sexto de primaria dentro
                del videojuego Minecraft.
              </p>
              <p style={{ fontWeight: 100 }}>
                El objetivo era que, a través de la versión educativa del
                popular juego de Microsoft, los alumnos pudieran trabajar una
                serie de unidades educativas de manera gamificada, dentro de un
                mundo creado específicamente hecho para ellos. Además de los
                conocimientos de primaria, los alumnos pudieron aprender
                pensamiento computacional gracias las unidades didácticas
                creadas para tal fin, utilizando el lenguaje de programación
                Scratch.
              </p>
              <p style={{ fontWeight: 100 }}>
                Los centros participantes eligieron a cuatro alumnos para
                representar a su colegio en la final regional, disputada en el
                Aulario de la Universidad de La Laguna, en la que compitieron
                dentro de un mundo reto de Minecraft y se evaluaron tanto sus
                conocimientos del currículo de primaria como las habilidades
                blandas como el trabajo en equipo, la comunicación y el
                liderazgo, además del pensamiento computacional.
              </p>
              <p style={{ fontWeight: 100 }}>
                Este proyecto fue posible gracias al patrocinio de Fundación
                DISA y a la colaboración de Microsoft España, la Universidad de
                La Laguna y el proyecto MineAcademy. La organización y
                producción estuvo a cargo de la startup Gaming Experience, de la
                que varios miembros forman parte de A1M.
              </p>
            </div>
          </div>
          {/* <Spacing lg="115" md="60" /> */}
          {/* <div className="cs_portfolio_details_gallery">
            <div>
              <img src="/images/others/portfolio_details_2.jpeg" alt="Thumb" />
            </div>
            <div>
              <img src="/images/others/portfolio_details_3.jpeg" alt="Thumb" />
            </div>
            <div>
              <img src="/images/others/portfolio_details_4.jpeg" alt="Thumb" />
            </div>
          </div> */}
        </div>
        {/* <Spacing lg="90" md="60" /> */}
        {/* <div className="cs_page_navigation cs_center">
          <div>
            <a href="/" className="cs_text_btn cs_type1">
              <Icon icon="cil:arrow-left" className="cs_fs_21" />
              <span>Prev Project</span>
            </a>
          </div>
          <div>
            <a href="/" className="cs_text_btn">
              <span>Next Project</span>
              <Icon icon="cil:arrow-right" className="cs_fs_21" />
            </a>
          </div>
        </div> */}
      </div>
      <Spacing lg="80" md="40" />
    </>
  );
}
