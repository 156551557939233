import React, { useEffect, useState } from "react";
import VideoModal from "../VideoModal";
import TextTransition, { presets } from "react-text-transition";

export default function Hero({ title, subtitle, videoSrc, bgUrl, imgUrl }) {
  const [index, setIndex] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((prevIndex) => prevIndex + 1),
      3000
    );

    return () => clearInterval(intervalId);
  }, []);
  return (
    <section className="cs_hero cs_style_1 cs_primary_bg position-relative">
      <div
        className="cs_custom"
        style={{
          backgroundImage: `url(${imgUrl})`,
        }}
      >
        <div className="cs_hero_text text-center position-relative cs_custom_grid">
          <p
            className="cs_hero_subtitle cs_accent_color cs_medium cs_fs_18 cs_dancing_animation cs_custom_font_title"
            style={{ color: "white", fontWeight: "bold" }}
          >
            <TextTransition springConfig={presets.wobbly}>
              {title[index % title.length]}
            </TextTransition>
          </p>
          <h1 className="cs_hero_title cs_white_color cs_fs_68 cs_dancing_animation cs_custom_font">
            {subtitle}
          </h1>
        </div>
      </div>
      {/* <div>
        <div>
          {videoSrc ? (
            <VideoModal videoSrc={videoSrc} bgUrl={bgUrl} />
          ) : (
            <img
              src={imgUrl}
              alt="Hero"
              style={{ width: "100%", height: "auto" }}
            />
          )}
        </div>
      </div> */}
    </section>
  );
}
