import React from "react";
import Hero from "../Hero";
import Spacing from "../Spacing";
import FunFact from "../FunFact";
import About from "../About";
import WhyChose from "../WhyChose";
import Service from "../Service";
import PortfolioSlider from "../Portfolio/PortfolioSlider";
import Button from "../Button";
import SectionHeading from "../SectionHeading";
import Award from "../Award";
import Accordion from "../Accordion";
import Cta from "../Cta";
import TestimonialSlider from "../Slider/TestimonialSlider";
import PostCarousel from "../Slider/PostCarousel";
import { pageTitle } from "../../helpers/PageTitle";

const funfactData = [
  { title: "AÑOS DE EXPERIENCIA", number: "18" },
  { title: "PROYECTOS", number: "+50" },
  { title: "EVENTOS PRESENCIALES", number: "+30" },
  { title: "PERSONAS EN EL EQUIPO", number: "12" },
];

const whyChoseFeatureData = [
  {
    title: "Promovemos la industria gaming y su cultura",
    content:
      "Fomentamos su crecimiento y visibilidad a todos los niveles. A través de iniciativas integradoras, buscamos acercar el mundo del videojuego a todos los sectores, apoyando a todos los actores de esta industria.",
  },
  {
    title: "Actividades, contenidos, proyectos y servicios gaming",
    content:
      "Desde concursos de KPOP, conciertos o competiciones de esports pasando por eventos profesionales o asesoría. Realizamos una gestión 360º donde el protagonista siempre eres tú.",
  },
  {
    title:
      "Relacionamos marcas, empresas e instituciones con los gamers y su industria",
    content:
      "Revolucionamos la experiencia gamer y creamos un caldo de cultivo perfecto donde las marcas, las instituciones públicas y los consumidores se dan la mano.Una visión diferente al desarrollo de experiencias en la industria del videojuego y el entretenimiento.",
  },
  {
    title: "Ecosistema global",
    content:
      "Creamos un entorno único donde la innovación, la creatividad y las nuevas tecnologías converjan, potenciando el desarrollo de diversas industrias y facilitando la creación de sinergias.",
  },
];
const serviceListData = [
  {
    title: "Eventos presenciales",
    icon: (
      <div style={{ textAlign: "center" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={75}
          height={75}
          viewBox="0 0 32 32"
        >
          <path
            fill="#D2D2D2"
            d="M26 14h-2v2h2a3.003 3.003 0 0 1 3 3v4h2v-4a5.006 5.006 0 0 0-5-5M24 4a3 3 0 1 1-3 3a3 3 0 0 1 3-3m0-2a5 5 0 1 0 5 5a5 5 0 0 0-5-5m-1 28h-2v-2a3.003 3.003 0 0 0-3-3h-4a3.003 3.003 0 0 0-3 3v2H9v-2a5.006 5.006 0 0 1 5-5h4a5.006 5.006 0 0 1 5 5zm-7-17a3 3 0 1 1-3 3a3 3 0 0 1 3-3m0-2a5 5 0 1 0 5 5a5 5 0 0 0-5-5m-8 3H6a5.006 5.006 0 0 0-5 5v4h2v-4a3.003 3.003 0 0 1 3-3h2zM8 4a3 3 0 1 1-3 3a3 3 0 0 1 3-3m0-2a5 5 0 1 0 5 5a5 5 0 0 0-5-5"
          ></path>
        </svg>
      </div>
    ),
    href: "/services#eventos-presenciales",
  },
  {
    title: "Producción audiovisual",
    icon: (
      <div style={{ textAlign: "center" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={75}
          height={75}
          viewBox="0 0 24 24"
        >
          <path
            fill="#D2D2D2"
            d="M6 16h8v-.55q0-1.1-1.1-1.775T10 13t-2.9.675T6 15.45zm4-4q.825 0 1.413-.587T12 10t-.587-1.412T10 8t-1.412.588T8 10t.588 1.413T10 12m-6 8q-.825 0-1.412-.587T2 18V6q0-.825.588-1.412T4 4h12q.825 0 1.413.588T18 6v4.5l4-4v11l-4-4V18q0 .825-.587 1.413T16 20zm0-2h12V6H4zm0 0V6z"
          ></path>
        </svg>
      </div>
    ),
    href: "/services#produccion-audiovisual",
  },
  {
    title: "Marketing digital",
    icon: (
      <div style={{ textAlign: "center" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={70}
          height={70}
          viewBox="0 0 16 16"
        >
          <path
            fill="#D2D2D2"
            d="M14.25 2.1a1.25 1.25 0 0 0-1.17-.1L6.91 4.43a1.22 1.22 0 0 1-.46.09H2.5a1.25 1.25 0 0 0-1.25 1.25v.1H0v3h1.25V9a1.25 1.25 0 0 0 1.25 1.22L4 13.4a1.26 1.26 0 0 0 1.13.72h.63A1.25 1.25 0 0 0 7 12.87v-2.53l6.08 2.43a1.27 1.27 0 0 0 .47.09a1.29 1.29 0 0 0 .7-.22a1.25 1.25 0 0 0 .55-1V3.13a1.25 1.25 0 0 0-.55-1.03m-8.5 3.67V9H2.5V5.77zm0 7.1h-.63l-1.23-2.65h1.86zm1.62-3.72A2.29 2.29 0 0 0 7 9V5.7a2.26 2.26 0 0 0 .37-.11l6.18-2.46v8.48zm7.46-3.03v2.5a1.25 1.25 0 0 0 0-2.5"
          ></path>
        </svg>
      </div>
    ),
    href: "/services#marketing-digital",
  },
  {
    title: "Desarrollo de proyectos tecnológicos",
    icon: (
      <div style={{ textAlign: "center" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={75}
          height={75}
          viewBox="0 0 24 24"
        >
          <g fill="none" fillRule="evenodd">
            <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"></path>
            <path
              fill="#D2D2D2"
              d="M15 2a1 1 0 0 1 1 1v1h1a3 3 0 0 1 3 3v1h1a1 1 0 1 1 0 2h-1v4h1a1 1 0 1 1 0 2h-1v1a3 3 0 0 1-3 3h-1v1a1 1 0 1 1-2 0v-1h-4v1a1 1 0 1 1-2 0v-1H7a3 3 0 0 1-3-3v-1H3a1 1 0 1 1 0-2h1v-4H3a1 1 0 0 1 0-2h1V7a3 3 0 0 1 3-3h1V3a1 1 0 0 1 2 0v1h4V3a1 1 0 0 1 1-1m2 4H7a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1m-3 2a2 2 0 0 1 1.995 1.85L16 10v4a2 2 0 0 1-1.85 1.995L14 16h-4a2 2 0 0 1-1.995-1.85L8 14v-4a2 2 0 0 1 1.85-1.995L10 8zm0 2h-4v4h4z"
            ></path>
          </g>
        </svg>
      </div>
    ),
    href: "/services#desarrollo-proyectos-tecnologicos",
  },
  {
    title: "Plataforma",
    icon: (
      <div style={{ textAlign: "center" }}>
        <svg
          id="Capa_2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 509 509"
          width={75}
          height={75}
        >
          <g id="Capa_1-2" data-name="Capa_1" fill="#D2D2D2">
            <path d="M299.59,310.5h-106.19v16h106.19v-16Z" />
            <path d="M442.18,454.5h-55.35v16h55.35v-16Z" />
            <path d="M493,219.6V46.49c-.04-25.6-20.89-46.46-46.5-46.49H46.49C20.89.04.04,20.89,0,46.5v272.01c.04,25.6,20.89,46.46,46.5,46.49h129.43c-.51,12.25-3.82,24.22-9.66,35-21.1.14-38.25,17.36-38.27,38.5v8c0,8,6.5,14.5,14.5,14.5h177.5v1.51c.04,25.6,20.89,46.46,46.5,46.49h96.01c25.6-.04,46.46-20.89,46.49-46.5v-208.02c-.04-13.37-5.97-26.17-16-34.88ZM198.07,400c4.17-11.19,6.52-23.08,6.88-35h83.1c.36,11.92,2.71,23.81,6.88,35h-96.86ZM320,365v18.41c-1.68-5.98-2.67-12.16-2.93-18.41h2.93ZM321.7,420.52v19.28h-167.81c0-3.4,0-19.28,12.61-19.28h155.2ZM320,301v35H46.5c-9.65,0-17.5-7.86-17.5-17.5v-17.5h291ZM464,208.02c-.5-.02-1-.02-1.5-.02h-96c-.45,0-.89.01-1.38.02-9.67.31-18.86,3.57-26.54,9.42-1.81,1.37-3.53,2.89-5.11,4.51-.2.2-.41.42-.66.68l-.21.22c-.19.2-.38.41-.54.59-.16.18-.32.36-.48.55h-.17l-1.95,2.56c-5.87,7.7-9.13,16.88-9.45,26.61-.01.44-.02.89-.02,1.33v17.5H29V46.5c0-9.65,7.86-17.5,17.5-17.5h400c9.65,0,17.5,7.86,17.5,17.5v161.52ZM349,254.5c0-9.65,7.86-17.5,17.5-17.5h96c9.65,0,17.5,7.86,17.5,17.5v1.5h-131v-1.5ZM480,285v131h-131v-131h131ZM480,445v17.5c0,9.65-7.86,17.5-17.5,17.5h-96c-9.65,0-17.5-7.86-17.5-17.5v-17.5h131Z" />
          </g>
        </svg>
      </div>
    ),
    href: "/services#plataforma",
  },
];
const portfolioData = [
  {
    href: "/portfolio/liga-canaria-de-esports",
    imgUrl: "/images/home/home-lces.jpg",
    title: "Liga Canaria de Esports",
    btnText: "Ver proyecto",
  },
  {
    href: "/portfolio/juegos-escolares-minecraft",
    imgUrl: "/images/home/home-jeme.jpg",
    title: "Juegos Escolares Minecraft Education",
    btnText: "Ver proyecto",
  },
  {
    href: "/portfolio/gg-worldwide-festival",
    imgUrl: "/images/home/home-gg.jpg",
    title: "GG Worldwide Festival",
    btnText: "Ver proyecto",
  },
  {
    href: "/portfolio/video-games-summit-tenerife",
    imgUrl: "/images/home/home-vgs.jpg",
    title: "Video Games Summit Tenerife",
    btnText: "Ver proyecto",
  },
  {
    href: "/portfolio/gaming-points",
    imgUrl: "/images/home/home-gamingpoint.jpg",
    title: "Gaming Points",
    btnText: "Ver proyecto",
  },
  {
    href: "/portfolio/simseries",
    imgUrl: "/images/home/home-simseries.jpg",
    title: "Simseries",
    btnText: "Ver proyecto",
  },
  {
    href: "/portfolio/siam-mall-chess-weekend",
    imgUrl: "/images/home/home-siam.jpg",
    title: "Siam Mall Chess Weekend",
    btnText: "Ver proyecto",
  },
];
// const awardData = [
//   {
//     brand: 'Behance',
//     title: 'UI/UX design of the month',
//     subTitle:
//       'Accusamus et iusto odio dignissimos ducimus qui blanditiis fedarals praesentium voluptatum deleniti atque corrupti quos dolores',
//     date: 'December 12, 2023',
//     awardImgUrl: '/images/creative-agency/award_img_1.svg',
//   },
//   {
//     brand: 'Awwwards',
//     title: 'CSS awards design',
//     subTitle:
//       'Accusamus et iusto odio dignissimos ducimus qui blanditiis fedarals praesentium voluptatum deleniti atque corrupti quos dolores',
//     date: 'January 05, 2022',
//     awardImgUrl: '/images/creative-agency/award_img_2.svg',
//   },
//   {
//     brand: 'Google',
//     title: 'Website of the day',
//     subTitle:
//       'Accusamus et iusto odio dignissimos ducimus qui blanditiis fedarals praesentium voluptatum deleniti atque corrupti quos dolores',
//     date: 'March 20, 2021',
//     awardImgUrl: '/images/creative-agency/award_img_3.svg',
//   },
// ];
const testimonialData = [
  {
    text: "Zivans Motion Graphics did an excellent job on my video related projects. The motion graphics added an extra layer of polish and really brought the video to life. I highly recommend their high quality services and work.",
    avatarName: "Ansari Patron",
    avatarDesignation: "CEO at Delta",
  },
  {
    text: "Zivans Motion Graphics did an excellent job on my video related projects. The motion graphics added an extra layer of polish and really brought the video to life. I highly recommend their high quality services and work.",
    avatarName: "Jhon Doe",
    avatarDesignation: "Manager at Delta",
  },
  {
    text: "Zivans Motion Graphics did an excellent job on my video related projects. The motion graphics added an extra layer of polish and really brought the video to life. I highly recommend their high quality services and work.",
    avatarName: "Ramatam Coo",
    avatarDesignation: "MD at Delta",
  },
];
// const faqData = [
//   {
//     title: '01. I need your services and how can i contact you throw email?',
//     content:
//       'Marketing eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born.',
//   },
//   {
//     title: '02. What are the different types of service we provide?',
//     content:
//       'Marketing eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born.',
//   },
//   {
//     title: '03. What are the different stages of the working process?',
//     content:
//       'Marketing eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born.',
//   },
//   {
//     title: '04. What is the difference between direct and digital marketing?',
//     content:
//       'Marketing eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born.',
//   },
//   {
//     title: '05. How can i payment proceed after complete project?',
//     content:
//       'Marketing eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born.',
//   },
// ];

const postData = [
  {
    thumbnailSrc: "/images/creative-agency/post_1.jpeg",
    title: "Hoy nace A1M",
    date: "07 Mar 2023",
    url: "/blog/hoy-nace-a1m",
  },
  // {
  //   thumbnailSrc: '/images/creative-agency/post_2.jpeg',
  //   title: 'Artistic mind will be great for creation anything',
  //   date: '22 Apr 2023',
  //   url: '/blog/blog-details',
  // },
  // {
  //   thumbnailSrc: '/images/creative-agency/post_3.jpeg',
  //   title: 'AI will take over all job for human within few years',
  //   date: '13 May 2023',
  //   url: '/blog/blog-details',
  // },
  // {
  //   thumbnailSrc: '/images/creative-agency/post_4.jpeg',
  //   title: 'Your agency need to replace some artistic mind people',
  //   date: '15 Mar 2023',
  //   url: '/blog/blog-details',
  // },
  // {
  //   thumbnailSrc: '/images/creative-agency/post_1.jpeg',
  //   title: 'How to keep fear from ruining your art business with confident',
  //   date: '07 Mar 2023',
  //   url: '/blog/blog-details',
  // },
  // {
  //   thumbnailSrc: '/images/creative-agency/post_2.jpeg',
  //   title: 'Artistic mind will be great for creation anything',
  //   date: '22 Apr 2023',
  //   url: '/portfolio/portfolio-details',
  // },
  // {
  //   thumbnailSrc: '/images/creative-agency/post_3.jpeg',
  //   title: 'AI will take over all job for human within few years',
  //   date: '13 May 2023',
  //   url: '/portfolio/portfolio-details',
  // },
  // {
  //   thumbnailSrc: '/images/creative-agency/post_4.jpeg',
  //   title: 'Your agency need to replace some artistic mind people',
  //   date: '15 Mar 2023',
  //   url: '/portfolio/portfolio-details',
  // },
];

const aboutSubtitle = (
  <div style={{ textAlign: "justify" }}>
    <p>
      A1M es una empresa de base tecnológica que opera dentro del sector de los
      videojuegos. Un equipo multidisciplinar con alcance global con más de 20
      años de experiencia en la industria del gaming
    </p>
    <p>
      Hemos colaborado con importantes marcas como Intel, Amazon, Google, Nvidia
      y Microsoft, así como con editoriales como Riot Games, Electronic Arts,
      Ubisoft, Bandai Namco o Epic Games durante más de una década.
    </p>
    <p>
      Además, hemos desarrollado una de las plataformas de esports más grandes
      de Europa, con más de 30.000 usuarios activos. Además, hemos organizado
      exitosos festivales y eventos que han acumulado una asistencia de más de
      800.000 personas.
    </p>
    <p>
      Lideramos la innovación en la industria del entretenimiento a través de
      los videojuegos, los esports y la cultura pop.
    </p>
  </div>
);

export default function Home() {
  pageTitle("Home");
  return (
    <>
      <Hero
        title={[
          "Eventos presenciales",
          "Competiciones online",
          "Marketing digital",
          "Producción audiovisual",
          "Desarrollo de proyectos tecnológicos",
          "Plataforma gaming propia",
          "I+D+i",
          "Tecnología puntera",
          "Metodologías ágiles de desarrollo de software",
        ]}
        subtitle="CONNECTING CULTURES, EMOTIONS & WORLDS"
        imgUrl="/images/home-hero.jpg"
      />
      <Spacing lg="125" md="70" />
      <div className="container">
        <FunFact data={funfactData} />
      </div>
      <Spacing lg="75" md="45" />
      <div style={{ backgroundColor: "#000000" }}>
        <Spacing lg="75" md="45" />
        <div className="container cs_fjalla_one_responsive" style={{}}>
          <p>
            Una visión diferente al desarrollo de experiencias en la industria
            del videojuego y del entretenimiento
          </p>
        </div>
        <Spacing lg="75" md="45" />
        <About
          thumbnail="/images/home/que-es-a1m.jpg"
          title="¿QUÉ ES A1M?"
          // title="Full-stack creatives and designing agency"
          subTitle={aboutSubtitle}
          featureList={[
            "Eventos presenciales",
            "Producción audiovisual",
            "Desarrollo de proyectos tecnológicos",
          ]}
          btnText="Saber más"
          btnUrl="/about"
        />
        <Spacing lg="150" md="75" />
      </div>
      <Spacing lg="50" md="30" />
      <WhyChose
        // sectionTitle="We have depth of market knowledge"
        sectionTitle="¿QUÉ HACEMOS?"
        whyChoseFeatureData={whyChoseFeatureData}
        thumbnailSrc="/images/home/que-hacemos.jpg"
      />
      <Spacing lg="150" md="80" />

      <section className="cs_primary_bg">
        <Spacing lg="70" md="40" />
        <div className="container">
          <SectionHeading
            title="SERVICIOS"
            // subTitle="Servicios"
            variantColor="cs_accent_color"
          />
          <Spacing lg="45" md="30" />
        </div>
        <div className="container">
          <Service
            sectionTitle="Nuestros servicios principales"
            sectionSubTitle="Servicios"
            data={serviceListData}
          />
          <Spacing lg="135" md="65" />
        </div>
      </section>
      <section>
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading
            title="PORTFOLIO"
            subTitle=""
            variantColor="cs_accent_color"
          />
          <Spacing lg="45" md="45" />
          <PortfolioSlider data={portfolioData} />
          <Spacing lg="26" md="30" />
          <div className="text-center">
            <Button btnText="Ver todos los proyectos" btnUrl="/portfolio" />
          </div>
        </div>
        <Spacing lg="150" md="80" />
      </section>

      {/* AWARDS SECTION */}

      {/* <section className="cs_primary_bg cs_shape_animation_2">
        <Spacing lg="143" md="75" />
        <div className="cs_shape_1 position-absolute">
          <svg
            width={65}
            height={64}
            viewBox="0 0 65 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.5">
              <path
                d="M62.4554 25.9314C55.6838 19.6081 40.1618 12.4752 32.1637 20.1537C41.7609 21.9206 53.2379 29.2392 48.3751 39.1677C45.1712 45.7019 38.7353 45.7177 33.3337 41.995C27.338 37.8739 25.7108 31.2667 27.4596 24.5962C26.5312 24.5866 25.6039 24.6605 24.6889 24.8172C9.80991 27.7447 14.0713 47.6353 20.9187 55.948C22.4528 57.8045 19.7488 60.3159 18.1393 58.4837C7.86403 46.8126 6.49349 23.0691 25.5532 19.9295C26.8892 19.7254 28.2446 19.6801 29.5912 19.7945C36.9845 9.42053 56.5698 17.4866 64.055 24.4366C65.1096 25.4175 63.4831 26.8926 62.4554 25.9314ZM33.9938 39.0327C38.3927 42.4636 44.2429 40.8527 44.3919 34.8698C44.6036 28.2263 35.7464 25.0921 29.1457 24.655C27.1454 29.9313 29.4427 35.4836 33.9938 39.0327Z"
                fill="#A3A3A3"
              />
            </g>
          </svg>
        </div>
  
        <div className="container">
          <SectionHeading
            title="Our prize achievement"
            subTitle="Awards"
            variantColor="cs_white_color"
          />
          <Spacing lg="85" md="45" />
          <Award data={awardData} />
        </div> 
        <Spacing lg="150" md="80" />
     </section> */}
      {/* <TestimonialSlider
        layeredImages={[
          '/images/creative-agency/layer_img_1.jpeg',
          '/images/creative-agency/layer_img_2.jpeg',
          '/images/creative-agency/layer_img_3.jpeg',
          '/images/creative-agency/layer_img_4.jpeg',
          '/images/creative-agency/layer_img_5.jpeg',
        ]}
        data={testimonialData}
      /> */}
      <section>
        <div className="container">
          <Cta
            title="¿Tienes algún proyecto en mente?"
            btnText="Contáctanos"
            btnUrl="/contact"
            bgUrl="/images/creative-agency/cta_bg.jpg"
          />
        </div>
      </section>
      {/* FIXME: BLOG */}
      <Spacing lg="120" md="50" />
      {/* <section className="cs_p76_full_width">
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading
            title="BLOG"
            subTitle=""
            variantColor="cs_accent_color"
          />
          <Spacing lg="85" md="45" />
        </div>
        <PostCarousel data={postData} />
        <Spacing lg="120" md="50" />
      </section> */}

      {/* FAQ SECTION */}
      {/* <section>
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading title="Frequently asked question" subTitle="FAQs" />
          <Spacing lg="55" md="30" />
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <Accordion variant="cs_type_1" data={faqData} />
            </div>
          </div>
        </div>
        <Spacing lg="120" md="50" />
      </section> */}
    </>
  );
}
