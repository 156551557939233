import React from 'react';

export default function SectionHeadingStyle5({ title }) {
  return (
    <div className="cs_section_heading cs_style_4 text-center">
      <div className="cs_height_10 cs_height_lg_5" />
      <h2 className="cs_section_title cs_fs_50 mb-0 cs_white_color">
        <span
          className="d-inline-flex cs_accent_color position-relative wow fadeInUp"
          data-wow-duration="0.8s"
          data-wow-delay="0.2s"
        >
          {title}
          <svg
            width={84}
            height={77}
            viewBox="0 0 84 77"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M65.3624 15.1377C61.5058 17.236 58.2272 20.3009 54.5066 22.6275C50.8588 24.9056 47.4588 27.6547 44.151 30.3651C37.4432 35.8488 31.3183 42.2895 27.1848 49.9347C26.6359 50.9499 27.9765 52.0525 28.812 51.1879C34.7183 45.0338 40.785 39.0449 46.9536 33.1677C49.9554 30.2987 52.9992 27.4669 56.0852 24.6724C59.3492 21.7241 63.0941 19.3295 66.3144 16.3714C66.9847 15.7497 66.125 14.72 65.3624 15.1377Z"
              fill="#A3A3A3"
            />
            <path
              d="M82.9079 43.9094C78.5174 43.8868 74.167 44.9898 69.7856 45.2327C65.4914 45.4683 61.1869 46.2361 56.9819 47.0144C48.4608 48.5818 39.9886 51.2685 32.6796 55.9711C31.7091 56.5955 32.3514 58.208 33.5003 57.8537C41.6428 55.3126 49.8461 52.9935 58.0848 50.8214C62.0982 49.7565 66.1305 48.7445 70.1818 47.7855C74.4632 46.7776 78.8987 46.4868 83.1466 45.4494C84.0337 45.2281 83.7774 43.9114 82.9079 43.9094Z"
              fill="#A3A3A3"
            />
            <path
              d="M32.8229 0.855889C30.7094 4.70418 29.6042 9.05405 27.7283 13.0211C25.8876 16.9079 24.5097 21.0576 23.1886 25.1247C20.5027 33.362 18.824 42.09 19.4719 50.7569C19.558 51.9078 21.2816 52.1121 21.5181 50.9334C23.1675 42.5645 25.041 34.2482 27.0607 25.9708C28.0386 21.9353 29.0721 17.9084 30.1611 13.8902C31.3169 9.64629 33.1765 5.6089 34.2903 1.38044C34.5189 0.495243 33.2393 0.0925867 32.8229 0.855889Z"
              fill="#A3A3A3"
            />
          </svg>
        </span>
      </h2>
    </div>
  );
}
