import React, { useEffect } from "react";
import { useLocation } from "react-router-dom"; // Importar useLocation
import Spacing from "../Spacing";
import SectionHeadingStyle3 from "../SectionHeading/SectionHeadingStyle3";
import ServiceStyle2 from "../Service/ServiceStyle2";
import SectionHeading from "../SectionHeading";
import PricingTableList from "../PricingTable/PricingTableList";
import CtaStyle2 from "../Cta/CtaStyle2";
import { pageTitle } from "../../helpers/PageTitle";

const serviceData = [
  {
    number: "01",
    title: "Eventos presenciales",
    subTitle:
      "Equipo con más de 18 años de experiencia desarrollando eventos tecnológicos, en especial dentro de la industria de los videojuegos. Durante los últimos años, este grupo ha organizado, entre otras cosas, Tenerife GG 2022 y 2023, Gamelab Video Games Summit Tenerife 2022, Gaming Points en tres municipios, las finales de la Liga Canaria de Esports durante cinco temporadas y las finales presenciales de Juegos Escolares Minecraft Edition.",
    icon: (
      <div style={{ textAlign: "center", paddingTop: "25px" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={150}
          height={150}
          viewBox="0 0 32 32"
        >
          <path
            fill="#D2D2D2"
            d="M26 14h-2v2h2a3.003 3.003 0 0 1 3 3v4h2v-4a5.006 5.006 0 0 0-5-5M24 4a3 3 0 1 1-3 3a3 3 0 0 1 3-3m0-2a5 5 0 1 0 5 5a5 5 0 0 0-5-5m-1 28h-2v-2a3.003 3.003 0 0 0-3-3h-4a3.003 3.003 0 0 0-3 3v2H9v-2a5.006 5.006 0 0 1 5-5h4a5.006 5.006 0 0 1 5 5zm-7-17a3 3 0 1 1-3 3a3 3 0 0 1 3-3m0-2a5 5 0 1 0 5 5a5 5 0 0 0-5-5m-8 3H6a5.006 5.006 0 0 0-5 5v4h2v-4a3.003 3.003 0 0 1 3-3h2zM8 4a3 3 0 1 1-3 3a3 3 0 0 1 3-3m0-2a5 5 0 1 0 5 5a5 5 0 0 0-5-5"
          ></path>
        </svg>
      </div>
    ),

    slug: "eventos-presenciales",
  },
  {
    number: "02",
    title: "Producción audiovisual",
    subTitle:
      "Ponemos a tú disposición a un gran equipo multidisciplinar capaz de crear eventos y festivales donde más de 800.000 personas han disfrutado con nosotros.",
    icon: (
      <div style={{ textAlign: "center", paddingTop: "10px" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={180}
          height={180}
          viewBox="0 0 24 24"
        >
          <path
            fill="#D2D2D2"
            d="M6 16h8v-.55q0-1.1-1.1-1.775T10 13t-2.9.675T6 15.45zm4-4q.825 0 1.413-.587T12 10t-.587-1.412T10 8t-1.412.588T8 10t.588 1.413T10 12m-6 8q-.825 0-1.412-.587T2 18V6q0-.825.588-1.412T4 4h12q.825 0 1.413.588T18 6v4.5l4-4v11l-4-4V18q0 .825-.587 1.413T16 20zm0-2h12V6H4zm0 0V6z"
          ></path>
        </svg>
      </div>
    ),
    slug: "produccion-audiovisual",
  },
  {
    number: "03",
    title: "Marketing digital",
    subTitle:
      "Nuestro equipo de marketing está formado por profesionales con dilatada experiencia en el sector del entretenimiento. Creamos un nuevo camino para que tú estrategia encaje perfectamente con nosotros. Desarrollamos tus ideas y las llevamos a cabo.",
    icon: (
      <div style={{ textAlign: "center", paddingTop: "25px" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={150}
          height={150}
          viewBox="0 0 16 16"
        >
          <path
            fill="#D2D2D2"
            d="M14.25 2.1a1.25 1.25 0 0 0-1.17-.1L6.91 4.43a1.22 1.22 0 0 1-.46.09H2.5a1.25 1.25 0 0 0-1.25 1.25v.1H0v3h1.25V9a1.25 1.25 0 0 0 1.25 1.22L4 13.4a1.26 1.26 0 0 0 1.13.72h.63A1.25 1.25 0 0 0 7 12.87v-2.53l6.08 2.43a1.27 1.27 0 0 0 .47.09a1.29 1.29 0 0 0 .7-.22a1.25 1.25 0 0 0 .55-1V3.13a1.25 1.25 0 0 0-.55-1.03m-8.5 3.67V9H2.5V5.77zm0 7.1h-.63l-1.23-2.65h1.86zm1.62-3.72A2.29 2.29 0 0 0 7 9V5.7a2.26 2.26 0 0 0 .37-.11l6.18-2.46v8.48zm7.46-3.03v2.5a1.25 1.25 0 0 0 0-2.5"
          ></path>
        </svg>
      </div>
    ),
    slug: "marketing-digital",
  },
  {
    number: "04",
    title: "Desarrollo de proyectos tecnológicos",
    subTitle:
      "Diseñamos estrategias y estudios de desarrollo de la industria del videojuego para territorios y entidades. Además, ayudamos a empresas a diseñar un modelo de negocio perfecto para su entrada en el sector poniendo el foco en la innovación y el emprendimiento.",
    icon: (
      <div style={{ textAlign: "center", paddingTop: "25px" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={150}
          height={150}
          viewBox="0 0 24 24"
        >
          <g fill="none" fillRule="evenodd">
            <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"></path>
            <path
              fill="#D2D2D2"
              d="M15 2a1 1 0 0 1 1 1v1h1a3 3 0 0 1 3 3v1h1a1 1 0 1 1 0 2h-1v4h1a1 1 0 1 1 0 2h-1v1a3 3 0 0 1-3 3h-1v1a1 1 0 1 1-2 0v-1h-4v1a1 1 0 1 1-2 0v-1H7a3 3 0 0 1-3-3v-1H3a1 1 0 1 1 0-2h1v-4H3a1 1 0 0 1 0-2h1V7a3 3 0 0 1 3-3h1V3a1 1 0 0 1 2 0v1h4V3a1 1 0 0 1 1-1m2 4H7a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1m-3 2a2 2 0 0 1 1.995 1.85L16 10v4a2 2 0 0 1-1.85 1.995L14 16h-4a2 2 0 0 1-1.995-1.85L8 14v-4a2 2 0 0 1 1.85-1.995L10 8zm0 2h-4v4h4z"
            ></path>
          </g>
        </svg>
      </div>
    ),
    slug: "desarrollo-proyectos-tecnologicos",
  },
  {
    number: "05",
    title: "Asesoramiento en la industria del videojuego",
    subTitle:
      "A través de la división A1M Consulting contamos con los mejores profesionales a nivel de experiencia, prestigio y cualificación con los que poder acreditar unos servicios de asesoramiento, acompañamiento y dirección de proyectos de máxima calidad.",
    icon: (
      <div style={{ textAlign: "center", paddingTop: "15px" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={160}
          height={160}
          viewBox="0 0 14 14"
        >
          <g
            fill="none"
            stroke="#D2D2D2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M4.4 7v2.31m1.156-1.154H3.245m-1.989-.963l-.412 3.71a2.283 2.283 0 0 0 4.311 1.272l.36-.718h2.97l.36.718a2.283 2.283 0 0 0 4.31-1.273l-.411-3.71a3 3 0 0 0-2.982-2.668H4.238a3 3 0 0 0-2.982 2.669"></path>
            <path d="M7 4.524v-.98a1 1 0 0 1 1-1h1.466a1 1 0 0 0 1-1V.561m0 6.934a.248.248 0 0 1 0-.495m0 .495a.248.248 0 1 0 0-.495M8.733 8.733a.248.248 0 0 1 .495 0m-.495 0a.248.248 0 0 0 .495 0"></path>
          </g>
        </svg>
      </div>
    ),
    slug: "asesoramiento",
  },
  {
    number: "06",
    title: "Plataforma",
    subTitle: (
      <div>
        <p>
          Plataforma centralizada que sirve como columna vertebral tecnológica
          para todos nuestros productos. Está diseñada para ser robusta,
          escalable y adaptable a todas las necesidades, tanto para usuarios
          como para clientes.
        </p>
        <p>Funciones clave:</p>
        <div style={{ lineHeight: "0.5em" }}>
          <p>- Diseño personalizable</p>
          <p>- Gestión de inscripciones</p>
          <p>- Herramientas de comunicación</p>
          <p>- Calendario de actividades</p>
          <p>- Resultados de competiciones en tiempo real</p>
        </div>
      </div>
    ),
    icon: (
      <div style={{ textAlign: "center", paddingTop: "25px" }}>
        <svg
          id="Capa_2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 509 509"
          width={150}
          height={150}
        >
          <g id="Capa_1-2" data-name="Capa_1" fill="#D2D2D2">
            <path d="M299.59,310.5h-106.19v16h106.19v-16Z" />
            <path d="M442.18,454.5h-55.35v16h55.35v-16Z" />
            <path d="M493,219.6V46.49c-.04-25.6-20.89-46.46-46.5-46.49H46.49C20.89.04.04,20.89,0,46.5v272.01c.04,25.6,20.89,46.46,46.5,46.49h129.43c-.51,12.25-3.82,24.22-9.66,35-21.1.14-38.25,17.36-38.27,38.5v8c0,8,6.5,14.5,14.5,14.5h177.5v1.51c.04,25.6,20.89,46.46,46.5,46.49h96.01c25.6-.04,46.46-20.89,46.49-46.5v-208.02c-.04-13.37-5.97-26.17-16-34.88ZM198.07,400c4.17-11.19,6.52-23.08,6.88-35h83.1c.36,11.92,2.71,23.81,6.88,35h-96.86ZM320,365v18.41c-1.68-5.98-2.67-12.16-2.93-18.41h2.93ZM321.7,420.52v19.28h-167.81c0-3.4,0-19.28,12.61-19.28h155.2ZM320,301v35H46.5c-9.65,0-17.5-7.86-17.5-17.5v-17.5h291ZM464,208.02c-.5-.02-1-.02-1.5-.02h-96c-.45,0-.89.01-1.38.02-9.67.31-18.86,3.57-26.54,9.42-1.81,1.37-3.53,2.89-5.11,4.51-.2.2-.41.42-.66.68l-.21.22c-.19.2-.38.41-.54.59-.16.18-.32.36-.48.55h-.17l-1.95,2.56c-5.87,7.7-9.13,16.88-9.45,26.61-.01.44-.02.89-.02,1.33v17.5H29V46.5c0-9.65,7.86-17.5,17.5-17.5h400c9.65,0,17.5,7.86,17.5,17.5v161.52ZM349,254.5c0-9.65,7.86-17.5,17.5-17.5h96c9.65,0,17.5,7.86,17.5,17.5v1.5h-131v-1.5ZM480,285v131h-131v-131h131ZM480,445v17.5c0,9.65-7.86,17.5-17.5,17.5h-96c-9.65,0-17.5-7.86-17.5-17.5v-17.5h131Z" />
          </g>
        </svg>
      </div>
    ),
    slug: "plataforma",
  },
];

export default function ServicePage() {
  pageTitle("Service");
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        const elementPosition =
          element.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({ top: elementPosition - 120, behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title="Mucho más que un juego"
        subTitle="Servicios"
        shape="shape_2"
      />
      <Spacing lg="75" md="60" />
      <div className="container">
        <ServiceStyle2 data={serviceData} />
      </div>
      <div className="cs_height_140 cs_height_lg_70" />
      <CtaStyle2
        title="¿Tienes algún proyecto específico en mente? <br />¡Envíanos un mensaje!"
        btnText="Contactar"
        btnUrl="/contact"
      />
      <div className="cs_height_150 cs_height_lg_80" />
    </>
  );
}
