import React from "react";
import { Link } from "react-router-dom";

export default function ServiceStyle2({ data, variantColor }) {
  return (
    <ul
      className={`cs_image_box_1_list ${
        variantColor ? variantColor : ""
      } cs_mp0`}
    >
      {data?.map((item, index) => (
        <li key={index} id={item.slug}>
          <div className="cs_image_box cs_style_1">
            <div className="cs_image_box_number cs_primary_color cs_primary_font cs_fs_38 cs_semibold">
              {item.number}
            </div>
            <div className="cs_image_box_img cs_radius_15 overflow-hidden">
              {item.thumbnailSrc && (
                <img src={item.thumbnailSrc} alt="Service" />
              )}
              {item.icon && !item.thumbnailSrc && <>{item.icon}</>}
            </div>
            <div className="cs_image_box_info position-relative">
              <h2 className="cs_image_box_title cs_fs_29 cs_semibold">
                <div>{item.title}</div>
              </h2>
              <p
                className="cs_image_box_subtitle mb-0"
                style={{ fontWeight: 100, textAlign: "justify" }}
              >
                {item.subTitle}
              </p>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
}
