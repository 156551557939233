import React from 'react';
import Spacing from '../Spacing';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import { pageTitle } from '../../helpers/PageTitle';

export default function Cookies() {
  pageTitle('Política de Cookies');
  return (
    <>
      <Spacing lg="100" md="40" />
      <SectionHeadingStyle3 title="POLÍTICA DE COOKIES" />
      <Spacing lg="50" md="40" />
      <div className="container">
        <p>
          En esta web se utilizan cookies propias y/o de terceros para analizar el uso del sitio
          web y mostrarle publicidad relacionada con sus preferencias o intereses sobre la base
          de un perfil elaborado a partir de sus hábitos de navegación.
        </p>
        <p>
          Es de vital importancia que lea detenidamente la presente política de cookies y
          comprenda la importancia y valor que, para A1M, tienen sus hábitos de navegación
          por el sitio web de su titularidad, y las ventajas asociadas a la información que facilita
          por este medio.
        </p>
        <p>
          Podrá aceptar, rechazar y/o configurar las cookies empleadas en este espacio web, a
          su antojo, con excepción de aquellas de carácter técnico, las cuales se emplean para
          el correcto funcionamiento y desarrollo del sitio web, y que únicamente se detallan por
          una cuestión de cortesía o deferencia con el usuario final, al no estar sujetas a las
          obligaciones normativas impuestas por el artículo 22.2 de la LSSI.
        </p>
        <h2>ENTIDAD RESPONSABLE</h2>
        <p>
          La entidad responsable de la recogida, procesamiento y utilización de los datos
          facilitados a través de la página web: www.a1m.es, propiedad de A1m Gamesports,
          S.L. | A1M, con NIF B70999941 y dirección en C/ Villalba Hervás 12, 1º, 38002 · Santa Cruz de Tenerife
        </p>
        <h2>¿QUÉ SON LAS COOKIES?</h2>
        <p>
          Son pequeños archivos de texto, a menudo encriptados, que se ubican en los
          directorios del navegador. Su objetivo es registrar la visita del usuario y guardar cierta
          información, permitiendo a la página web funcionar de manera más eficiente y
          conseguir una mayor personalización y análisis sobre el comportamiento del usuario.
        </p>
        <p>
          Así, cada vez que el usuario regresa a la misma página, el navegador recupera este
          archivo y lo envía al servidor de la página.
        </p>
        <h2>¿QUÉ TIPO DE COOKIES EXISTEN?</h2>
        <h3>En función de la entidad que las gestione</h3>
        <ul>
          <li><strong>Cookies propias:</strong> Son aquellas de las que es responsable el propio editor, en
          este caso, A1m Gamesports, S.L. | A1M, y que, generalmente, se envían al
          equipo terminal del usuario desde un equipo o dominio gestionado por el propio
          editor.</li>
          <li><strong>Cookies de terceros:</strong> Son aquellas de las que es responsable una entidad
          distinta del editor y que, generalmente, se envían al equipo terminal del usuario
          desde un equipo o dominio que no es gestionado por el editor.</li>
        </ul>
        <h3>En función de su finalidad</h3>
        <ul>
          <li><strong>Cookies técnicas:</strong> Son aquellas que permiten al usuario la navegación a través
          de una página web, plataforma o aplicación y la utilización de las diferentes
          opciones o servicios que en ella existan.</li>
          <li><strong>Cookies de preferencia o personalización:</strong> Son aquellas que permiten recordar
          información para que el usuario acceda al servicio con determinadas
          características que pueden diferenciar su experiencia de la de otros usuarios
          (Ej: Idioma, moneda, tamaño de fuente, etc.).</li>
          <li><strong>Cookies analíticas:</strong> Son aquellas que permiten al responsable de las mismas el
          seguimiento y análisis del comportamiento de los usuarios de los sitios web.</li>
          <li><strong>Cookies de publicidad comportamental:</strong> Son aquellas que almacenan
          información del comportamiento de los usuarios obtenida a través de la
          observación continuada de sus hábitos de navegación.</li>
        </ul>
        <h3>En función del plazo de vigencia</h3>
        <ul>
          <li><strong>Cookies de sesión:</strong> Son aquellas diseñadas para recabar y almacenar datos
          mientras el usuario accede a una página web.</li>
          <li><strong>Cookies persistentes:</strong> Son aquellas en las que los datos siguen almacenados
          en el terminal y pueden ser accedidos y tratados durante un periodo definido
          por el responsable.</li>
        </ul>
        <h2>DECLARACIÓN DE COOKIES</h2>
        <p>
          [_] Cookies Técnicas
        </p>
        <p>
          Son aquellas que permiten al usuario la navegación a través de una página web,
          plataforma o aplicación y la utilización de las diferentes opciones o servicios. Estas
          cookies estarán exceptuadas del cumplimiento de las obligaciones establecidas en el
          artículo 22.2 de la LSSI.
        </p>
       
       <p>
        Esta web no usa cookies técnicas.
       </p>

        <p>
          ** Sin embargo, si estas cookies se utilizan también para finalidades no exentas
          (Ej: fines publicitarios comportamentales), quedarán sujetas a la obligación de
          información y deber de obtención de consentimiento por parte del usuario).
        </p>
        <h2>COOKIES DE PERSONALIZACIÓN Y REGISTRO</h2>
        <p>
          Son aquellas que permiten recordar información para que el usuario acceda al servicio
          con determinadas características que pueden diferenciar su experiencia de la de otros
          usuarios (Ej: Idioma, aspecto o contenido del servicio, moneda, tamaño de fuente, etc).
        </p>
        <p>
          Esta web no usa cookies de personalización y registro.
        </p>
        <h2>COOKIES DE RENDIMIENTO Y ANALÍTICAS</h2>
        <p>
          Son aquellas que permiten al responsable de las mismas el seguimiento y análisis del
          comportamiento de los usuarios de los sitios web.
        </p>
        <table>
          <thead>
            <tr>
              <th>Denominación</th>
              <th>Titular</th>
              <th>Duración</th>
              <th>Finalidad</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>_ga</td>
              <td>Google Analytics</td>
              <td>2 años</td>
              <td>Se usa para distinguir a los usuarios.</td>
            </tr>
            <tr>
              <td>_ga_ID</td>
              <td>Google Analytics</td>
              <td>2 años</td>
              <td>Se usa para mantener el estado de la sesión.</td>
            </tr>
          </tbody>
        </table>
        <h2>COOKIES DE PUBLICIDAD COMPORTAMENTAL</h2>
        <p>
          Son aquellas que almacenan información del comportamiento de los usuarios obtenida
          a través de la observación continuada de sus hábitos de navegación, lo que permite
          desarrollar un perfil específico para mostrar publicidad en función del mismo.
        </p>
        <p>
          Esta web no usa cookies de publicidad comportamental.
        </p>
        <p>
          **Respecto a las cookies persistentes se recomienda reducir al mínimo
          necesario su duración temporal atendiendo a la finalidad de su uso.
        </p>
        <h2>COOKIES DE TERCEROS</h2>
        <p>
          Además de las cookies titularidad de A1m Gamesports, S.L. | A1M, esta web emplea
          cookies de terceros ("Ad-Servers"). De este modo, estos terceros pueden almacenar
          cookies enviadas desde los servicios de A1m Gamesports, S.L. | A1M procedentes de
          los navegadores de los usuarios, así como acceder a los datos que en ellas se
          guardan.
        </p>
        <p>
          Las empresas que generan estas cookies tienen sus propias políticas de privacidad.
          En la actualidad, solo tenemos cookies de terceros de Google Analytics.
        </p>
        <p>
          Para más información, acuda a:
          <br />
          <a href='https://support.google.com/analytics/answer/7318509?hl=es'>https://support.google.com/analytics/answer/7318509?hl=es</a>
        </p>
        <h2>¿CÓMO PUEDO DESHABILITAR LAS COOKIES EN MI NAVEGADOR?</h2>
        <p>
          Se pueden configurar los diferentes navegadores para avisar al usuario de la
          recepción de cookies y, si se desea, impedir su instalación en el equipo. Asimismo, el
          usuario puede revisar en su navegador qué cookies tiene instaladas y cuál es el plazo
          de caducidad de las mismas, pudiendo eliminarlas.
        </p>
        <p>
          Para ampliar esta información consulte las instrucciones y manuales de su navegador
          (con carácter enunciativo y no limitativo):
        </p>
        <ul>
          <li>
            Para más información sobre la administración de las cookies en Google Chrome:
            <a href="https://support.google.com/chrome/answer/95647?hl=es"> Enlace</a>
          </li>
          <li>
            Para más información sobre la administración de las cookies en Internet Explorer:
            <a href="http://windows.microsoft.com/es-es/windows-vista/cookies-frequently-asked-questions"> Enlace</a>
          </li>
          <li>
            Para más información sobre la administración de las cookies en Mozilla Firefox:
            <a href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we"> Enlace</a>
          </li>
          <li>
            Para más información sobre la administración de las cookies en Safari:
            <a href="http://www.apple.com/es/privacy/use-of-cookies/"> Enlace</a>
          </li>
          <li>
            Para más información sobre la administración de las cookies en Opera:
            <a href="http://help.opera.com/Windows/11.50/es-ES/cookies.html"> Enlace</a>
          </li>
        </ul>
        <p>
          Si desea dejar de ser seguido por Google Analytics visite:
          <a href="http://tools.google.com/dlpage/gaoptout"> Enlace</a>
        </p>
        <h2>ACTUALIZACIONES Y CAMBIOS EN LA POLÍTICA DE PRIVACIDAD/COOKIES</h2>
        <p>
          A1m Gamesports, S.L. | A1M podrá modificar esta Política de Cookies en función de
          las exigencias legislativas, reglamentarias, o con la finalidad de adaptar dicha política
          a las instrucciones dictadas por la Agencia Española de Protección de Datos en cada
          momento, por ello se aconseja a los usuarios que la visiten periódicamente.
        </p>
      </div>
      <Spacing lg="80" md="40" />
    </>
  );
}
