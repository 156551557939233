import React from "react";
import { Helmet } from "react-helmet";
import Spacing from "../Spacing";
import SectionHeadingStyle3 from "../SectionHeading/SectionHeadingStyle3";
import { pageTitle } from "../../helpers/PageTitle";
import image from "./images/porfolio_gamingpoint.jpg";

const title = "Gaming Points";
const description = "";

export default function PortFolioGamingPoints() {
  pageTitle(title);
  return (
    <>
      <Helmet>
        {/*  OG metadata */}

        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
        {/* og current url */}
        <meta property="og:url" content={window.location.href} />

        {/*  END OG metadata */}
      </Helmet>
      {/* <Spacing lg="70" md="70" /> */}
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3 title={title} subTitle="Detalles del proyecto" />
      <Spacing lg="75" md="60" />
      <div className="container">
        <div className="cs_portfolio_details">
          <img src={image} alt="Thumb" />
          <Spacing lg="100" md="40" />
          <div className="cs_portfolio_details_in">
            <div className="cs_portfolio_details_right">
              <h2>Resumen del proyecto:</h2>

              <p style={{ fontWeight: 100 }}>
                <span>
                  <span>
                    Eventos itinerantes donde se lleva el gaming y la cultura
                    pop a los municipios, ofreciendo un evento innovador
                    alrededor de la promoción del lugar, impulsando con ello el
                    comercio y el consumo local, y donde apoyamos el desarrollo
                    de la industria y el talento gamer de la localidad.
                  </span>
                </span>
              </p>
              <p style={{ fontWeight: 100 }}>&nbsp;</p>
              <p style={{ fontWeight: 100 }}>
                <span>
                  <span>Características de los Gaming Points:</span>
                </span>
              </p>
              <ul>
                <li style={{ fontWeight: 100 }}>
                  <span>
                    <span>
                      Decenas de actividades con los videojuegos como hilo
                      conductor
                    </span>
                  </span>
                </li>
                <li style={{ fontWeight: 100 }}>
                  <span>
                    <span>
                      Espacios de exhibición, promoción y competición en varias
                      modalidades
                    </span>
                  </span>
                </li>
                <li style={{ fontWeight: 100 }}>
                  <span>
                    <span>
                      Atrayendo al público del municipio, así como
                      colindantes&nbsp;
                    </span>
                  </span>
                </li>
                <li style={{ fontWeight: 100 }}>
                  <span>
                    <span>
                      Seguimiento y promoción de contenidos online pre, durante
                      y posevento
                    </span>
                  </span>
                </li>
                <li style={{ fontWeight: 100 }}>
                  <span>
                    <span>
                      El municipio entra a formar parte de un circuito,
                      convirtiéndose en una parada de referencia anual
                    </span>
                  </span>
                </li>
              </ul>
            </div>
          </div>
          {/* <Spacing lg="115" md="60" /> */}
          {/* <div className="cs_portfolio_details_gallery">
            <div>
              <img src="/images/others/portfolio_details_2.jpeg" alt="Thumb" />
            </div>
            <div>
              <img src="/images/others/portfolio_details_3.jpeg" alt="Thumb" />
            </div>
            <div>
              <img src="/images/others/portfolio_details_4.jpeg" alt="Thumb" />
            </div>
          </div> */}
        </div>
        {/* <Spacing lg="90" md="60" /> */}
        {/* <div className="cs_page_navigation cs_center">
          <div>
            <a href="/" className="cs_text_btn cs_type1">
              <Icon icon="cil:arrow-left" className="cs_fs_21" />
              <span>Prev Project</span>
            </a>
          </div>
          <div>
            <a href="/" className="cs_text_btn">
              <span>Next Project</span>
              <Icon icon="cil:arrow-right" className="cs_fs_21" />
            </a>
          </div>
        </div> */}
      </div>
      <Spacing lg="80" md="40" />
    </>
  );
}
