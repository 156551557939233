import React from 'react';
import Spacing from '../Spacing';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import { pageTitle } from '../../helpers/PageTitle';

export default function Legal() {
  pageTitle('AVISO LEGAL');
  return (
    <>
      <Spacing lg="100" md="40" />
      <div style={{ maxWidth: '1028px', margin: '0 auto', textAlign: 'left', fontWeight: 100 }}>
        <SectionHeadingStyle3 title="AVISO LEGAL" />
        <p>
          El presente sitio "web" ha sido diseñado por A1m Gamesports, S.L., para dar a conocer y permitir el acceso
          general de todos los/las usuarios/as a la información, actividades, productos y servicios diversos, propios o
          de terceros, ofertados.
        </p>
        <p>
          La presente información conforma y regula las condiciones de uso, las limitaciones de responsabilidad y las
          obligaciones que los/las usuarios/as de la página Web que se publica bajo el nombre de dominio <a href="http://www.a1m.es" target="_blank" rel="noopener noreferrer">a1m.es </a>
          asumen y se comprometen a respetar.
        </p>
        <p>
          El presente texto, regula e informa de:
        </p>
        <ol>
          <li>Datos identificativos del titular de la web</li>
          <li>Definiciones</li>
          <li>Política de privacidad</li>
          <li>Disponibilidad de la página</li>
          <li>Condiciones de uso</li>
          <li>Disponibilidad de contenidos</li>
          <li>Responsabilidad de contenidos</li>
          <li>Reproducción de contenidos</li>
          <li>Propiedad industrial e intelectual</li>
          <li>Medidas de seguridad</li>
          <li>Protección de datos de carácter personal</li>
          <li>Publicidad</li>
          <li>Limitación de responsabilidad</li>
          <li>Nulidad parcial</li>
          <li>Jurisdicción</li>
          <li>Legislación aplicable</li>
        </ol>
        <h2>1. DATOS IDENTIFICATIVOS DEL TITULAR DE LA WEB</h2>
        <p>
          En cumplimiento del deber de información, A1m Gamesports, S.L. (en lo sucesivo La persona Titular) en
          calidad de titular del web site <a href="http://www.a1m.es" target="_blank" rel="noopener noreferrer">www.a1m.es</a> (en lo sucesivo LA WEB) procede a comunicarles los datos
          identificativos:
        </p>
        <p>
          Denominación social: A1m Gamesports, S.L.<br />
          NIF: B70999941<br />
          Dirección de correo electrónico: a1m @ protecciondedatos.info<br />
          Domicilio: C/ Villalba Hervás 12, 1º, 38002 · Santa Cruz de Tenerife
          <br />
        </p>
        <h2>2. DEFINICIONES</h2>
        <p>
          Entre otros términos, se utiliza;
        </p>
        <ul>
          <li>“Página”; Dominio/s que se pone a disposición de los/las usuarios/as de Internet.</li>
          <li>“usuaria”; Persona física o jurídica que utiliza o navega por la Página.</li>
          <li>“Contenido”; Son las páginas que conforman la totalidad del dominio, las cuales conforman la información y
          los servicios que La persona titular pone a disposición de los/las usuarios/as de Internet.</li>
          <li>“Web”; Palabra técnica que describe el sistema de acceso a la información vía Internet.</li>
          <li>“Enlaces, Links, Vínculos, etc..”; Técnica por la cual la persona usuaria puede navegar por diferentes
          páginas de la Web, o por Internet, con un simple click sobre el texto, icono, botón o indicativo que contiene
          el enlace.</li>
          <li>“Cookies”; Medio técnico para la trazabilidad y seguimiento de la navegación en los sitios Web.</li>
        </ul>
        <h2>3. POLÍTICA DE PRIVACIDAD</h2>
        <p>
          A través de este sitio web no se recaban datos de carácter personal de los/las usuarios/as sin su
          conocimiento, ni se ceden a terceros.
        </p>
        <p>
          Con la finalidad de ofrecerle el mejor servicio y con el objeto de facilitar el uso, se analizan el número de
          páginas visitadas, el número de visitas, así como la actividad de los/las visitantes y su frecuencia de
          utilización. A estos efectos, A1m Gamesports, S.L. utiliza la información estadística elaborada por el
          proveedor de servicios de internet.
        </p>
        <p>
          A1m Gamesports, S.L. podrá o no, utilizar cookies, en aquellos casos que se utilicen cookies, La persona
          usuaria será advertido/a en el primer acceso a la página informándole de su uso y política de uso.
        </p>
        <p>
          El portal del que es titular A1m Gamesports, S.L. podrá contener enlaces a sitios web de terceros, cuyas
          políticas de privacidad son ajenas a la de A1m Gamesports, S.L. Al acceder a tales sitios web La persona
          usuaria puede decidir si acepta sus políticas de privacidad y de cookies.
        </p>
        <h2>4. DISPONIBILIDAD DE LA PÁGINA</h2>
        <p>
          La persona titular no garantiza la inexistencia de interrupciones o errores en el acceso a la Página o a sus
          Contenidos, ni que éstos se encuentren actualizados, aunque desarrollará sus mejores esfuerzos para, en
          su caso, evitarlos, subsanarlos o actualizarlos.
        </p>
        <h2>5. CONDICIONES DE USO</h2>
        <p>
          La simple y mera utilización de la Página otorga la condición de la persona usuaria, bien sea persona física
          o jurídica, y obligatoriamente implica la aceptación completa, plena y sin reservas de todas y cada una de
          las cláusulas y condiciones generales incluidas en el Aviso Legal.
        </p>
        <h2>6. DISPONIBILIDAD DE LOS CONTENIDOS</h2>
        <p>
          La prestación del servicio de la Página y de los Contenidos tiene, en principio, duración indefinida.
        </p>
        <h2>7. RESPONSABILIDAD DE LOS CONTENIDOS</h2>
        <p>
          La función de los links, enlaces, etc., que aparecen en esta Web es exclusivamente la de informar a la
          persona usuaria acerca de la existencia de otras Web que contienen información sobre la materia.
        </p>
        <h2>8. REPRODUCCIÓN DE CONTENIDOS</h2>
        <p>
          La persona usuaria se obliga y se compromete a no transmitir, difundir o poner a disposición de terceros
          cualquier clase de material contenido en la Página.
        </p>
        <h2>9. PROPIEDAD INDUSTRIAL E INTELECTUAL</h2>
        <p>
          Derechos de propiedad intelectual e industrial son todos los derechos reconocidos por la legislación de
          propiedad intelectual que tengan carácter patrimonial o de explotación para cualquier finalidad y para
          cualquier modalidad de uso.
        </p>
        <h2>10. MEDIDAS DE SEGURIDAD</h2>
        <p>
          La persona titular ha adoptado los niveles de seguridad de protección de los Datos Personales legalmente
          requeridos.
        </p>
        <h2>11. PROTECCIÓN DE DATOS DE CARÁCTER PERSONAL</h2>
        <p>
          Durante la navegación a través de LA WEB es posible sé que soliciten datos de carácter personal a través
          de diferentes formularios web dispuestos al efecto o por correo electrónico.
        </p>
        <h2>12. PUBLICIDAD</h2>
        <p>
          Parte del sitio "web" puede albergar contenidos publicitarios o estar patrocinado.
        </p>
        <h2>13. LIMITACIÓN DE RESPONSABILIDAD</h2>
        <p>
          La persona titular excluye toda responsabilidad por las decisiones que La persona usuaria pueda tomar
          basado en esta información.
        </p>
        <h2>14. NULIDAD PARCIAL</h2>
        <p>
          Si parte de estas condiciones generales de contratación fuera contraria a derecho, y por tanto, inválida,
          no afectará a las otras disposiciones que son conforme a derecho.
        </p>
        <h2>15. JURISDICCIÓN</h2>
        <p>
          Para cuantas cuestiones se susciten sobre la interpretación, aplicación y cumplimiento de este Aviso Legal,
          así como de las reclamaciones que puedan derivarse de su uso, todas las partes intervinientes se someten
          a los Jueces y Tribunales de S/C de Tenerife.
        </p>
        <h2>16. LEGISLACIÓN APLICABLE</h2>
        <p>
          El Aviso Legal se rige por la ley española. Reservados todos los derechos de autor por las leyes y tratados
          internacionales de propiedad intelectual.
        </p>
      </div>
      <Spacing lg="80" md="40" />
    </>
  );
}
