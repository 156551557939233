import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import "swiper/swiper-bundle.css";
import { Link } from "react-router-dom";

import "swiper/css";
import "./style.css";

export default function PortfolioSlider({ data }) {
  return (
    <div className="custom-swiper-wrapper">
      <Swiper
        modules={[Pagination, Navigation]}
        pagination={true}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        slidesPerView="auto"
        spaceBetween={12}
        className="portfolioSwiper"
        // speed={300}
        rewind={true}
        // grabCursor={true}
        style={{
          height: "600px",
        }}
      >
        {data.map((item, index) => (
          <SwiperSlide key={index} className="portfolio-slide">
            <div key={index} className="portfolio_grid_custom">
              <Link to={item.href} className="cs_portfolio_thumb_custom">
                <img src={item.imgUrl} alt="Portfolio" />
              </Link>
              <div
                className="cs_portfolio_info_custom"
                style={{ padding: "10px", textAlign: "center" }}
              >
                <h2 className="cs_portfolio_title_custom">
                  <Link to={item.href}>{item.title}</Link>
                </h2>
              </div>
            </div>
          </SwiperSlide>
        ))}
        <div className="swiper-button-next swiper-custom-nav"></div>
        <div className="swiper-button-prev swiper-custom-nav"></div>
      </Swiper>
    </div>
  );
}
