import React from 'react';
import Spacing from '../Spacing';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import { pageTitle } from '../../helpers/PageTitle';

export default function Privacy() {
  pageTitle('Política de privacidad');
  return (
    <>
      <Spacing lg="100" md="40" />
      <div style={{ maxWidth: '1028px', margin: '0 auto', textAlign: 'left', fontWeight: 100 }}>
        <SectionHeadingStyle3 title="PROTECCIÓN DE DATOS" />
        <p>
          A1m Gamesports, S.L., está especialmente sensibilizado/a en la protección de datos de carácter personal de los/las usuarios/as de los servicios del sitio Web.
        </p>
        <p>
          Mediante la presente Política de Privacidad (o Política de Protección de Datos) A1m Gamesports, S.L. informa a los/las usuarios/as del sitio web <a href="http://www.a1m.es" target="_blank" rel="noopener noreferrer">www.a1m.es</a> de los usos a los que se someten los datos de carácter personal que se recaban en sitio Web, con el fin de que decidan, libre y voluntariamente, si desean facilitar la información solicitada.
        </p>
        <p>
          A1m Gamesports, S.L., se reserva la facultad de modificar esta Política con el objeto de adaptarla a novedades legislativas, criterios jurisprudenciales, prácticas del sector, o intereses de la entidad. Por ello, este Aviso Legal está sujeto a cambios y actualizaciones por lo que la versión publicada puede ser diferente en cada momento en que el/la usuario/a acceda. Por tanto, el/la usuario/a debe leerlo en todas y cada una de las ocasiones en que acceda.
        </p>
        
        <h3>RESPONSABLE</h3>
        <p>
          El Responsable del Tratamiento de los datos de carácter personal es A1m Gamesports, S.L., con dirección a estos efectos en C/ Villalba Hervás 12, 1º, 38002 · Santa Cruz de Tenerife

          <br />
          Email: a1m @ protecciondedatos.info
        </p>

        <h3>FINALIDAD DEL TRATAMIENTO</h3>
        <p>
          A1m Gamesports, S.L. tratará los datos aportados por los/las usuarios/as con el fin de gestionar la solicitud que se realice por los mismos.
        </p>

        <h3>PRINCIPIOS DE APLICACIÓN AL TRATAMIENTO DE LOS DATOS</h3>
        <ul>
          <li>Los datos personales deben ser tratados de forma lícita, leal y transparente.</li>
          <li>Los datos personales deben ser recogidos con fines determinados explícitos y legítimos.</li>
          <li>Los datos personales deben ser adecuados, pertinentes y limitados a lo necesario en relación con el tratamiento.</li>
          <li>Los datos personales deben ser exactos y estar siempre actualizados.</li>
          <li>Los datos personales deben mantenerse de forma que se permita la identificación de los/las interesados/as durante no más tiempo del necesario para los fines del tratamiento.</li>
          <li>Los datos personales deben ser tratados de tal manera que se garantice su seguridad.</li>
        </ul>

        <h3>LEGITIMACIÓN PARA EL TRATAMIENTO DE LOS DATOS</h3>
        <p>
          A1m Gamesports, S.L. está legitimado al tratamiento de datos personales, en base al consentimiento otorgado por el/la interesado/a mediante la firma o aceptación de los pertinentes formularios y/o documentos, para uno o varios fines específicos.
        </p>

        <h3>COMUNICACIONES DE DATOS - DESTINATARIOS</h3>
        <p>
          No habrá cesión de datos personales a terceros/as, salvo obligación legal o previa autorización del interesado/a consintiendo de forma clara, inequívoca y expresa. No habrá transferencias internacionales de datos.
        </p>
        <ul>
          <li>Órganos jurisdiccionales.</li>
          <li>Administración General del Estado, de las Comunidades Autónomas, Local e Instituciones de carácter público competentes, para el ejercicio de competencias similares o cuando así lo establezca una ley.</li>
          <li>Entidades públicas que le vengan impuestas en norma de ley.</li>
          <li>Cualquier otra que resulte de la normativa vigente.</li>
        </ul>

        <h3>PLAZO DE CONSERVACIÓN</h3>
        <p>
          Dependiendo la tipología de datos objeto de tratamiento, los datos personales proporcionados se conservarán durante el plazo correspondiente para cumplir con las obligaciones legales, mientras no se oponga al tratamiento o revoque el consentimiento:
        </p>
        <ul>
          <li>Una vez extinguida la relación contractual y/o profesional, los mantendremos bloqueados durante los plazos de prescripción legal.</li>
        </ul>

        <h3>EXACTITUD DE LOS DATOS</h3>
        <p>
          Por otro lado, con el fin de que los datos obrantes en nuestros registros y ficheros, informáticos y/o en papel, siempre correspondan, se tratará de mantener actualizados. De manera que, a estos efectos, el/la interesado/a deberá comunicar cualquier cambio y/o modificación a A1m Gamesports, S.L.
        </p>

        <h3>DERECHOS DE LOS USUARIOS</h3>
        <p>
          No obstante, el/la interesado/a de los datos personales en todo caso podrá ejercitar los derechos que le asisten, de acuerdo con el Reglamento General de Protección de Datos, y que son:
        </p>
        <ul>
          <li>Derecho de Información.</li>
          <li>Derecho de Acceso.</li>
          <li>Derecho de Rectificación.</li>
          <li>Derecho de Cancelación.</li>
          <li>Derecho de Oposición.</li>
          <li>Derecho de Limitación de Tratamiento.</li>
          <li>Derecho a la Portabilidad de los Datos.</li>
          <li>Derecho a no ser Objeto de Decisiones Individualizadas.</li>
        </ul>

        <h3>DELEGADO PROTECCIÓN DE DATOS</h3>
        <p>
          A1m Gamesports, S.L. ha nombrado un Delegado de Protección de Datos, puede contactar por escrito a A1m Gamesports, S.L. REF: Delegado de Protección de Datos con dirección en C/ Subida al Mayorazgo, 13, Planta 2, puerta 15-3, Santa Cruz de Tenerife · 38110 Santa Cruz de Tenerife o al correo electrónico <a href="mailto:a1m@delegado-protecciondedatos.com">a1m@delegado-protecciondedatos.com</a>.
        </p>

        <h3>MEDIDAS DE SEGURIDAD</h3>
        <p>
          Finalmente se informa que A1m Gamesports, S.L., adoptará en su sistema de información las medidas técnicas y organizativas adecuadas, dando cumplimiento al principio de responsabilidad proactiva, a fin de garantizar la seguridad y confidencialidad de los datos almacenados.
        </p>

        <h3>MAS INFORMACIÓN EN EL TRATAMIENTO DE DATOS</h3>
        <p>
          Si tiene alguna pregunta sobre esta Política de Privacidad, rogamos que se ponga en contacto enviando un email a a1m @ protecciondedatos.info
        </p>
        <p>
          Puede consultar la información adicional y detallada sobre Protección de Datos en nuestra página web: <a href="http://a1m.protecciondedatos.info" target="_blank" rel="noopener noreferrer">http://a1m.protecciondedatos.info</a>
        </p>
        <p>
          Puede consultar la NORMATIVA VIGENTE DE PROTECCIÓN DE DATOS PERSONALES en: <a href="http://normativa.avisolegal.info" target="_blank" rel="noopener noreferrer">http://normativa.avisolegal.info</a>
        </p>
      </div>
      <Spacing lg="80" md="40" />
    </>
  );
}
