import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
// import 'swiper/css';
import "slick-carousel/slick/slick.css";
import "./sass/index.scss";

if (process.env.REACT_APP_LANDING !== "true") {
  import("./bootstrap").then(({ default: cookieConsent, bootstrap }) => {});
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
