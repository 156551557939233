import React from "react";
import Spacing from "../Spacing";
import SectionHeadingStyle3 from "../SectionHeading/SectionHeadingStyle3";
import Portfolio from "../Portfolio";
import CtaStyle2 from "../Cta/CtaStyle2";
import { pageTitle } from "../../helpers/PageTitle";

const portfolioData = [
  {
    href: "/portfolio/liga-canaria-de-esports",
    imgUrl: "/images/portfolio/ligacanaria.jpg",
    title: "Liga Canaria de Esports",
    btnText: "Ver proyecto",
  },
  {
    href: "/portfolio/juegos-escolares-minecraft",
    imgUrl: "/images/portfolio/jeme.jpg",
    title: "Juegos Escolares Minecraft Education",
    btnText: "Ver proyecto",
  },
  {
    href: "/portfolio/gg-worldwide-festival",
    imgUrl: "/images/portfolio/tgg.jpg",
    title: "GG Worldwide Festival",
    btnText: "Ver proyecto",
  },
  {
    href: "/portfolio/video-games-summit-tenerife",
    imgUrl: "/images/portfolio/vgs.jpg",
    title: "Video Games Summit Tenerife",
    btnText: "Ver proyecto",
  },
  {
    href: "/portfolio/gaming-points",
    imgUrl: "/images/portfolio/gamingpoint.jpg",
    title: "Gaming Points",
    btnText: "Ver proyecto",
  },
  {
    href: "/portfolio/simseries",
    imgUrl: "/images/portfolio/simseries.jpg",
    title: "Simseries",
    btnText: "Ver proyecto",
  },
  {
    href: "/portfolio/siam-mall-chess-weekend",
    imgUrl: "/images/portfolio/siam.jpg",
    title: "Siam Mall Chess Weekend",
    btnText: "Ver proyecto",
  },
];

export default function PortfolioPage() {
  pageTitle("Portfolio");
  return (
    <>
      {/* <Spacing lg="70" md="70" /> */}
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title="Nuestros proyectos"
        // subTitle="Nuestros proyectos"
        shape="shape_3"
      />
      <Spacing lg="75" md="60" />
      <div className="container">
        <Portfolio data={portfolioData} />
        <div className="cs_height_75 cs_height_lg_40" />
        <CtaStyle2
          title="¿Tienes algún proyecto en mente? <br />¡Contacta con nosotros!"
          btnText="Enviar mensaje"
          btnUrl="/contact"
        />
        <div className="cs_height_150 cs_height_lg_80" />
      </div>
    </>
  );
}
