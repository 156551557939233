import { Icon } from "@iconify/react";
import React from "react";
import { Link } from "react-router-dom";
const addressDataList = [
  "C. Subida al Mayorazgo 13",
  "38110, Santa Cruz de Tenerife",
  "+34 822 903 529",
  "info@a1m.es",
];

const serviceMenuList = [
  {
    title: "Eventos presenciales",
    href: "/services#eventos-presenciales",
  },
  {
    title: "Producción audiovisual",
    href: "/services#produccion-audiovisual",
  },
  {
    title: "Marketing Digital",
    href: "/services#marketing-digital",
  },
  {
    title: "Desarrollo de proyectos tecnológicos",
    href: "/services#desarrollo-proyectos-tecnologicos",
  },
  {
    title: "Asesoramiento en la industria del videojuego",
    href: "/services#asesoramiento",
  },
  {
    title: "Plataforma",
    href: "/services#plataforma",
  },
];
const LinksMenuList = [
  {
    title: "Home",
    href: "/",
  },
  {
    title: "Sobre nosotros",
    href: "/about",
  },
  {
    title: "Servicios",
    href: "/services",
  },
  // {
  //   title: 'Portfolio',
  //   href: 'portfolio',
  // },
  // FIXME: BLOG
  // {
  //   title: "Blog",
  //   href: "/blog",
  // },
  {
    title: "Contacto",
    href: "/contact",
  },
];
const policyMenuList = [
  {
    title: "Aviso Legal",
    href: "/legal",
  },
  {
    title: "Política de Privacidad",
    href: "/privacidad",
  },
  {
    title: "Política de Cookies",
    href: "/cookies",
  },
];
const socialBtnList = [
  {
    icon: "fa6-brands:linkedin-in",
    href: "https://www.linkedin.com/company/a1m-entertainment",
  },
  {
    icon: "fa6-brands:twitter",
    href: "https://x.com/a1m_es",
  },
  // {
  //   icon: "fa6-brands:youtube",
  //   href: "/",
  // },
  // {
  //   icon: "fa6-brands:facebook-f",
  //   href: "/",
  // },
];
export default function Footer() {
  return (
    <footer
      className="cs_fooer cs_bg_filed"
      style={{ backgroundImage: "url(/images/footer_bg.jpg)" }}
    >
      <div className="cs_fooer_main">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-12">
              <div className="cs_footer_item">
                <div className="cs_text_widget">
                  <img src="/images/logo.png" alt="Logo" />
                </div>
                <ul className="cs_menu_widget cs_mp0">
                  {addressDataList.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>
            </div>
            {window.innerWidth > 991 && (
              <div className="col-lg-3 col-sm-6">
                <div className="cs_footer_item">
                  <h2 className="cs_widget_title">Servicios</h2>
                  <ul className="cs_menu_widget cs_mp0">
                    {serviceMenuList.map((item, index) => (
                      <li key={index}>
                        <Link to={item.href}>{item.title}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
            <div className="col-lg-3 col-6 col-sm-6">
              <div className="cs_footer_item">
                <h2 className="cs_widget_title">Mapa web</h2>
                <ul className="cs_menu_widget cs_mp0">
                  {LinksMenuList.map((item, index) => (
                    <li key={index}>
                      <Link to={item.href}>{item.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-6 col-sm-6">
              <div className="cs_footer_item">
                <h2 className="cs_widget_title">Legal </h2>
                <div className="cs_bottom_footer_right">
                  <ul className="cs_menu_widget cs_mp0">
                    {policyMenuList.map((item, index) => (
                      <li key={index}>
                        <Link to={item.href}>{item.title}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="cs_bottom_footer">
          <div className="cs_bottom_footer_left">
            <div className="cs_social_btns cs_style_1">
              {socialBtnList.map((item, index) => (
                <Link to={item.href} className="cs_center" key={index}>
                  <Icon icon={item.icon} />
                </Link>
              ))}
            </div>
          </div>
          <div className="cs_copyright">
            Copyright © {new Date().getFullYear()} A1M.
          </div>
        </div>
      </div>
    </footer>
  );
}
