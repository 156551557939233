import React from "react";
import { Helmet } from "react-helmet";
import Spacing from "../Spacing";
import SectionHeadingStyle3 from "../SectionHeading/SectionHeadingStyle3";
import { pageTitle } from "../../helpers/PageTitle";
import image from "./images/portfolio_siamchest.jpg";

const title = "Siam Mall Chess Weekend";
const description =
  "Evento patrocinado por el Centro Comercial Siam Mall para los entusiastas del ajedrez, independientemente de su edad o nivel de habilidad.";

export default function PortfolioSiamMallChess() {
  pageTitle(title);
  return (
    <>
      <Helmet>
        {/*  OG metadata */}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
        {/* og current url */}
        <meta property="og:url" content={window.location.href} />

        {/*  END OG metadata */}
      </Helmet>
      {/* <Spacing lg="70" md="70" /> */}
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3 title={title} subTitle="Detalles del proyecto" />
      <Spacing lg="75" md="60" />
      <div className="container">
        <div className="cs_portfolio_details">
          <img src={image} alt="Thumb" />
          <Spacing lg="100" md="40" />
          <div className="cs_portfolio_details_in">
            <div className="cs_portfolio_details_right">
              <h2>Resumen del proyecto:</h2>
              <p style={{ fontWeight: 100 }}>
                <span>
                  <span>
                    Evento patrocinado por el Centro Comercial Siam Mall para
                    los entusiastas del ajedrez, independientemente de su edad o
                    nivel de habilidad. Reuniendo a jugadores, aficionados y
                    expertos, el centro comercial se convirtió en una plataforma
                    única para disfrutar y aprender sobre este fascinante
                    deporte mental en un entorno dinámico y accesible.
                  </span>
                </span>
              </p>
              <p style={{ fontWeight: 100 }}>
                <span>
                  <span>
                    Durante un fin de semana completo, los asistentes pudieron
                    participar en una serie de retos, diseñados tanto para
                    principiantes como para jugadores avanzados: partidas
                    simultáneas, ajedrez gigante, puzzle rush o partidas a
                    ciegas… Del mismo modo, se organizaron actividades
                    interactivas para familias y jóvenes, fomentando el interés
                    por el juego en las nuevas generaciones.
                  </span>
                </span>
              </p>
              <p style={{ fontWeight: 100 }}>&nbsp;</p>
              <p style={{ fontWeight: 100 }}>
                <span>
                  <span>
                    Además, Siam Mall Chess Weekend fue la sede de la segunda
                    fase del torneo que llevaba el mismo nombre, cuyos
                    clasificatorios se celebraron online para toda Canarias. Los
                    seis mejores jugadores de la ronda previa se enfrentaron en
                    una fase final que fue retransmitida vía streaming y narrada
                    por el FIDE Master José Herrera y la creadora de contenido
                    María Rodrigo ‘Merybliya’.
                  </span>
                </span>
              </p>
            </div>
          </div>
          {/* <Spacing lg="115" md="60" /> */}
          {/* <div className="cs_portfolio_details_gallery">
            <div>
              <img src="/images/others/portfolio_details_2.jpeg" alt="Thumb" />
            </div>
            <div>
              <img src="/images/others/portfolio_details_3.jpeg" alt="Thumb" />
            </div>
            <div>
              <img src="/images/others/portfolio_details_4.jpeg" alt="Thumb" />
            </div>
          </div> */}
        </div>
        {/* <Spacing lg="90" md="60" /> */}
        {/* <div className="cs_page_navigation cs_center">
          <div>
            <a href="/" className="cs_text_btn cs_type1">
              <Icon icon="cil:arrow-left" className="cs_fs_21" />
              <span>Prev Project</span>
            </a>
          </div>
          <div>
            <a href="/" className="cs_text_btn">
              <span>Next Project</span>
              <Icon icon="cil:arrow-right" className="cs_fs_21" />
            </a>
          </div>
        </div> */}
      </div>
      <Spacing lg="80" md="40" />
    </>
  );
}
